import { useState, useRef, useContext, useEffect } from 'react';
import PageTemplate from './PageTemplate';
import HaulerInfo from './HaulerInfo';
import Availability from './Availability';
import WellDone from './WellDone';
import BackBtn from './BackBtn';
import { MarketContext } from '../containers/Market';
import { scrollTop } from '../utils/miscellaneous';
import './MarketVisual.scss';

export default function MarketVisual() {
  const [step, setStep] = useState(0);
  const refScrollArea = useRef(null);
  const { mover, availability, checkStepFlag } = useContext(MarketContext);

  const scrollStep = (step) => {
    scrollTop(refScrollArea);
    setStep(step);
  };

  useEffect(() => {
    if (mover) {
      if (availability) {
        scrollStep(2);
      } else scrollStep(1);
    } else scrollStep(0);
  }, [mover, availability, checkStepFlag]);

  const formStatus = (formIndex) => {
    if (step > formIndex) return 'left';
    if (step < formIndex) return 'right';
    return 'active';
  };

  const prevStep = () => {
    scrollTop(refScrollArea);
    setStep((curStep) => curStep - 1);
  };

  return (
    <PageTemplate refScrollArea={refScrollArea}>
      <div id='market'>
        <HaulerInfo status={formStatus(0)} />
        <Availability status={formStatus(1)} />
        <WellDone status={formStatus(2)} prevStep={prevStep} />
        <BackBtn
          text='TO CONTACT INFO'
          onClick={() => setStep(0)}
          invisible={step === 0}
        />
      </div>
    </PageTemplate>
  );
}
