import { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { MarketContext } from '../containers/Market';
import LabelDate from './LabelDate';
import LabelInput from './LabelInput';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import './Availability.scss';

const initialValues = {
  pickup: '',
  destination: '',
  pickupStartAt: new Date(),
  pickupEndAt: new Date(),
};

export default function Availability({ status }) {
  const {
    mover,
    availability,
    availabilityCreate,
    availabilityUpdate,
    setCheckStepFlag,
  } = useContext(MarketContext);

  const [spinner, setSpinner] = useState(true);

  const saveAvailabilityInfo = async ({
    pickup,
    destination,
    pickupStartAt,
    pickupEndAt,
  }) => {
    pickupStartAt = moment(pickupStartAt).startOf('day').toDate();
    pickupEndAt = moment(pickupEndAt).endOf('day').toDate();

    try {
      if (availability) {
        if (
          availability.pickup === pickup &&
          availability.destination === destination &&
          moment(availability.pickupStartAt.toDate()).isSame(
            pickupStartAt,
            'day'
          ) &&
          moment(availability.pickupEndAt.toDate()).isSame(pickupEndAt, 'day')
        ) {
          setCheckStepFlag();
          return;
        }

        await availabilityUpdate({
          availabilityID: availability.id,
          pickup,
          destination,
          pickupStartAt,
          pickupEndAt,
        });
      } else {
        await availabilityCreate({
          proID: mover.id,
          pickup,
          destination,
          pickupStartAt,
          pickupEndAt,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const validationSchema = new Yup.ObjectSchema({
    pickup: Yup.string().required('Required'),
    destination: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSpinner(true);
      await saveAvailabilityInfo(values);
      setSpinner(false);
    },
  });

  useEffect(() => {
    const isLoading = availability === undefined;
    const isEmpty = availability === null;

    setSpinner(isLoading);

    if (isLoading || isEmpty) {
      formik.setValues(initialValues);
    } else {
      const { pickup, destination, pickupStartAt, pickupEndAt } = availability;
      formik.setValues({
        pickup,
        destination,
        pickupStartAt: pickupStartAt.toDate(),
        pickupEndAt: pickupEndAt.toDate(),
      });
    }
  }, [availability]);

  return (
    <OrderFormTemplate1Btn
      header='Your availability'
      status={status}
      btnText='CONTINUE'
      btnType='submit'
      form='availability-form'
      spinner={spinner}
    >
      <form id='availability-form' onSubmit={formik.handleSubmit}>
        <div className='block'>
          <p>Where are you driving</p>
          <div className='horizont'>
            <LabelInput
              label='From*'
              placeholder='Driving from'
              name='pickup'
              {...formik.getFieldProps('pickup')}
              error={formik.touched.pickup && formik.errors.pickup}
            />
            <LabelInput
              label='To*'
              placeholder='Driving to'
              name='destination'
              {...formik.getFieldProps('destination')}
              error={formik.touched.destination && formik.errors.destination}
            />
          </div>
        </div>
        <div className='block'>
          <p>When you can pickup the goods</p>
          <div className='horizont'>
            <LabelDate
              label='Earliest'
              className='width-50'
              minDate={new Date()}
              value={formik.values.pickupStartAt}
              onChange={(newVal) => {
                formik.setFieldValue('pickupStartAt', newVal);
                if (newVal > formik.values.pickupEndAt)
                  formik.setFieldValue('pickupEndAt', newVal);
              }}
            />
            <LabelDate
              label='Latest'
              className='width-50'
              minDate={formik.values.pickupStartAt}
              value={formik.values.pickupEndAt}
              onChange={(newVal) => formik.setFieldValue('pickupEndAt', newVal)}
            />
          </div>
        </div>
      </form>
    </OrderFormTemplate1Btn>
  );
}
