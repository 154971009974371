import { useEffect, useState, createContext } from 'react';
import { auth, db, functions } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  query,
  collection,
  where,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import MarketVisual from '../components/MarketVisual';

export const MarketContext = createContext();

const proCreate = httpsCallable(functions, 'pro-create');
const proUpdate = httpsCallable(functions, 'pro-update');
const availabilityCreate = httpsCallable(functions, 'availability-create');
const availabilityUpdate = httpsCallable(functions, 'availability-update');

export default function Market() {
  const [user] = useAuthState(auth);
  const [mover, setMover] = useState(undefined);
  const [availability, setAvailability] = useState(undefined);
  const [checkStepFlag, setCheckStepFlag] = useState(false);

  // Subscribing for mover's data for the user
  useEffect(() => {
    if (!user) {
      setMover(undefined);
      return;
    }

    const { uid } = user;

    const moversQuery = query(
      collection(db, 'movers'),
      where('userID', '==', uid)
    );

    const unsub = onSnapshot(moversQuery, (moversSnapshot) => {
      const movers = [];
      moversSnapshot.forEach((doc) =>
        movers.push({ id: doc.id, ...doc.data() })
      );

      if (movers.length === 0) {
        setMover(null);
        return;
      }

      if (movers.length > 1)
        console.error(
          `There is more than one mover for the user ${uid} exists`
        );

      setMover(movers[0]);
    });

    return unsub;
  }, [user]);

  // Subscribing for availability data for the mover
  useEffect(() => {
    if (!mover) {
      setAvailability(mover); // null or undefined
      return;
    }

    const { id } = mover;

    const availabilityQuery = query(
      collection(db, 'availability'),
      where('proID', '==', id),
      where('active', '==', true),
      where('pickupEndAt', '>=', Timestamp.fromDate(new Date()))
    );

    const unsub = onSnapshot(availabilityQuery, (availabilitySnapshot) => {
      const availability = [];
      availabilitySnapshot.forEach((doc) =>
        availability.push({ id: doc.id, ...doc.data() })
      );

      if (availability.length === 0) {
        setAvailability(null);
        return;
      }

      setAvailability(availability[0]);
    });

    return unsub;
  }, [mover?.id]);

  return (
    <MarketContext.Provider
      value={{
        mover,
        user,
        availability,
        checkStepFlag,
        proCreate,
        proUpdate,
        availabilityCreate,
        availabilityUpdate,
        setCheckStepFlag: () => setCheckStepFlag((flag) => !flag),
      }}
    >
      <MarketVisual />
    </MarketContext.Provider>
  );
}
