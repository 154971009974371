import { useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { MarketContext } from '../containers/Market';
import DetailsBlock from './DetailsBlock';
import LabelText from './LabelText';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Button from './Button';
import ModalYesNo from './ModalYesNo';
import './WellDone.scss';

export default function WellDone({ status, prevStep }) {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { availability, availabilityUpdate } = useContext(MarketContext);
  const { id, pickup, destination, pickupStartAt, pickupEndAt } =
    availability || {};

  return (
    <OrderFormTemplate1Btn
      header='Well Done!'
      status={status}
      spinner={spinner}
    >
      <div className='well-done'>
        <p>
          We've received your application and will contact you shortly. We will
          do the best to prevent your deadheading.
        </p>
        <DetailsBlock header='Application' edit={true} onClick={prevStep}>
          <LabelText label='Pickup point' text={pickup} />
          <LabelText label='Destination' text={destination} />
          <LabelText
            label='Earliest pickup time'
            text={moment(pickupStartAt?.toDate()).format('MMM D, YYYY')}
          />
          <LabelText
            label='Latest pickup time'
            text={moment(pickupEndAt?.toDate()).format('MMM D, YYYY')}
          />
        </DetailsBlock>
        <Button
          color='red'
          background='transparent'
          nohover
          small
          onClick={() => setShowCancelConfirmation(true)}
        >
          CANCEL
        </Button>
      </div>
      {document.getElementById('market') &&
        createPortal(
          <ModalYesNo
            className='cancel-confirmation'
            show={showCancelConfirmation}
            onNo={() => setShowCancelConfirmation(false)}
            onClose={() => setShowCancelConfirmation(false)}
            onYes={async () => {
              setShowCancelConfirmation(false);
              try {
                setSpinner(true);
                await availabilityUpdate({
                  availabilityID: id,
                  active: false,
                });
                setSpinner(false);
              } catch (error) {
                console.error(error.message);
                setSpinner(false);
              }
            }}
          >
            <h5 className='header'>Are you sure?</h5>
            <p>You want to cancel your application?</p>
          </ModalYesNo>,
          document.getElementById('market')
        )}
    </OrderFormTemplate1Btn>
  );
}
