import { useContext, useEffect, useState } from 'react';
import { isMobilePhone, isEmail, isURL } from 'validator';
import { useFormik } from 'formik';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import * as Yup from 'yup';
import { MarketContext } from '../containers/Market';
import LabelInput from './LabelInput';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import LabelTextArea from './LabelTextArea';
import './HaulerInfo.scss';

const initialValues = {
  name: '',
  phone: '',
  email: '',
  url: '',
  blurb: '',
};

export default function HaulerInfo({ status }) {
  const { mover, proCreate, proUpdate, setCheckStepFlag } =
    useContext(MarketContext);
  const [user] = useAuthState(auth);

  const [spinner, setSpinner] = useState(true);

  const saveHaulerInfo = async ({ name, phone, email, url, blurb }) => {
    try {
      if (mover) {
        if (
          mover.name === name &&
          mover.phone === phone &&
          mover.email === email &&
          mover.url === url &&
          mover.blurb === blurb
        ) {
          setCheckStepFlag();
          return;
        }

        await proUpdate({
          proID: mover.id,
          update: {
            name,
            phone,
            email,
            url,
            blurb,
          },
        });
      } else {
        await proCreate({
          userID: user.uid,
          name,
          phone,
          email,
          url,
          blurb,
          type: 'hauler',
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const validationSchema = new Yup.ObjectSchema({
    name: Yup.string().max(50, 'Too long').required('Required'),
    phone: Yup.string()
      .test('phone-validation', 'Invalid phone', (value) =>
        isMobilePhone(value, 'en-US')
      )
      .required('Required'),
    email: Yup.string()
      .test('email-validation', 'Invalid email', (value) =>
        isEmail(value, {
          domain_specific_validation: true,
          allow_utf8_local_part: false,
        })
      )
      .required('Required'),
    url: Yup.string()
      .test('url-validation', 'Invalid URL', (value) => isURL(value))
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSpinner(true);
      await saveHaulerInfo(values);
      setSpinner(false);
    },
  });

  useEffect(() => {
    const isLoading = mover === undefined;
    const isEmpty = mover === null;

    setSpinner(isLoading);

    if (isLoading || isEmpty) {
      formik.setValues(initialValues);
    } else {
      const { name, phone, email, url, blurb } = mover;
      formik.setValues({ name, phone, email, url, blurb });
    }
  }, [mover]);

  return (
    <OrderFormTemplate1Btn
      header='Contact info'
      btnText='CONTINUE'
      status={status}
      form='hauler-info'
      btnType='submit'
      spinner={spinner}
    >
      <form id='hauler-info' onSubmit={formik.handleSubmit}>
        <LabelInput
          label='Name*'
          placeholder='Name'
          name='name'
          {...formik.getFieldProps('name')}
          error={formik.touched.name && formik.errors.name}
        />
        <div className='horizont'>
          <LabelInput
            label='Phone*'
            placeholder='Phone'
            name='phone'
            {...formik.getFieldProps('phone')}
            error={formik.touched.phone && formik.errors.phone}
          />
          <LabelInput
            label='Email*'
            placeholder='Email'
            name='email'
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <LabelInput
          label={
            formik.touched.url && formik.errors.url
              ? 'Web page*'
              : 'Web or social media page*'
          }
          placeholder='Web or social media page'
          name='url'
          {...formik.getFieldProps('url')}
          error={formik.touched.url && formik.errors.url}
        />
        <LabelTextArea
          label='Blurb'
          placeholder='Blurb'
          name='blurb'
          rows={5}
          {...formik.getFieldProps('blurb')}
        />
      </form>
    </OrderFormTemplate1Btn>
  );
}
